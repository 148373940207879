<template>
  <div>
    <van-nav-bar
        :title="submitType === 'video' ? '上传视频' : '撰写文章'"
        left-text="取消"
        :border="false"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-button style="margin-top: 5px" color="#0066ff" round type="info" @click="submit" size="small">
          提交
        </van-button>
      </template>
    </van-nav-bar>
    <div style="padding: 5px 20px">
      <div>
        <van-field name="radio" label="任务期限">
          <template #input>
            <van-radio-group v-model="editForm.deadline" direction="horizontal">
              <van-radio name="1">指定天数</van-radio>
              <van-radio name="2">永久</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-if="editForm.deadline=='1'" v-model="editForm.jobDay" type="digit" label="任务天数"/>
        <van-field
            readonly
            clickable
            name="picker"
            :value="editForm.cateText"
            label="任务分类"
            placeholder="点击选择任务分类"
            @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
            readonly
            clickable
            name="picker"
            :value="editForm.jobDataTypeText"
            label="回复数据类型"
            placeholder="点击选择任务分类"
            @click="showPicker2 = true"
        />
        <van-popup v-model="showPicker2" position="bottom">
          <van-picker
              show-toolbar
              :columns="columns2"
              @confirm="onConfirm2"
              @cancel="showPicker2 = false"
          />
        </van-popup>
        <van-field name="radio" label="回复人性别">
          <template #input>
            <van-radio-group v-model="editForm.sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
              <van-radio name="0">任意</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
            v-model="editForm.rewardsCoin"
            type="digit"
            name="打赏金额"
            label="打赏金额"
            placeholder="请输入打赏金额"
        />
        <label v-if="submitType === 'article'||submitType === 'video'">
          <input v-model="articleForm.title" class="in-title" placeholder="请输入标题"/>
        </label>
        <label v-if="submitType === 'article'||submitType === 'video'">
          <textarea
              v-model="articleForm.content"
              class="in-content"
              placeholder="请输入正文 （Markdown文档）"
              rows="8"/>
        </label>
        <!--                <div v-if="submitType === 'video'" style="margin: 20px 0">
                            <van-uploader v-model="fileList"
                                          :after-read="onRead"
                                          :max-count="1"
                                          accept="video/*"/>
                        </div>-->
        <van-popup v-model="show" round position="top" :style="{ height: '220px' }">
          <div style="padding: 20px">
            <van-tag round
                     @click="selectTag(item)"
                     v-for="(item, index) in tagList" :key="index"
                     size="large"
                     plain type="primary"
                     style="margin-bottom: 10px;margin-right: 10px">
              {{ item.title }}
            </van-tag>
          </div>
        </van-popup>
      </div>
    </div>

    <div class="poster-btn" v-if="editForm.cateText=='图文区'" @click="posterShow = true">
      <img alt="" class="poster-btn-img" src="../assets/poster.svg">
      选择封面
    </div>
    <div class="poster-btn" v-if="editForm.cateText=='视频区'" @click="videoShow = true">
      <img alt="" class="poster-btn-img" src="../assets/poster.svg">
      选择视频
    </div>
    <van-popup v-model="posterShow" round position="bottom" :style="{ height: '200px' }">
      <div style="padding: 20px;text-align: center">
        <div style="padding-bottom: 30px;letter-spacing: 1px;color: rgba(0,0,0, .7);font-weight: bold">选择文章封面
        </div>
        <van-uploader :after-read="afterRead" :max-count="1" v-model="posterFile"/>
      </div>
    </van-popup>
    <van-popup v-model="videoShow" round position="bottom" :style="{ height: '200px' }">
      <div style="padding: 20px;text-align: center">
        <div style="padding-bottom: 30px;letter-spacing: 1px;color: rgba(0,0,0, .7);font-weight: bold">选择视频
        </div>
        <van-uploader v-model="fileList"
                      @delete="remove"
                      :after-read="afterRead2"
                      :max-count="1"
                      accept="video/*"/>
        <van-progress :percentage="progress"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {saveArticle} from "@/api/article";
import {uploadImg, getPostForm,getUploadUrl} from "@/api/user";
import axios from "axios";
import {checkArticleForm} from "@/utils/check";
import {BASE_RUL} from "@/utils/request";
import * as Minio from 'minio'
import {Toast} from "vant";

export default {
  name: "Upload",

  data() {
    return {
      tag: '',
      posterFile: [],
      fileList: [],
      tagList: [],
      show: false,
      posterShow: false,
      submitType: this.$route.query.type,
      articleForm: {
        uid: localStorage.getItem("uid"),
        title: '',
        hasPoster: false,
        type: '',
        poster: '',
        content: '',
      },
      videoForm: {
        title: '',
        uid: localStorage.getItem("uid"),
        type: '',
        fid: '',
      },
      fileRef: null,
      imgUrl: '',
      formData: {},
      editForm: {
        deadline: '1',
        jobDay: '',
        categoryId: '',
        cateText: '',
        jobDataType: '1',
        jobDataTypeText: '',
        sex: '1',
        rewardsCoin: ''
      },
      columns: [],
      columns2: [],
      showPicker: false,
      showPicker2: false,
      videoShow: false,
      progress: 0,
      videoUrl: ''
    }
  },

  mounted() {
    getPostForm({}).then((res) => {
      if (res.code == 0) {
        this.formData = res.result
        this.tagList = res.result.jobDataType
        this.columns = res.result.categoryId.map((item) => {
          return {
            text: item.title,
            value: item.id
          }
        })
        this.columns2 = res.result.jobDataType.map((item) => {
          return {
            text: item.title,
            value: item.value
          }
        })
      }
    })
  },

  methods: {

    submit() {
      if (this.submitType === 'video') {
        this.submitVideo()
      } else {
        this.submitArticle()
      }
    },

    onRead() {
      let formData = new FormData()
      let file = this.fileList[0].file
      formData.append("file", file, file.name)
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      axios.post(BASE_RUL + "/file/video", formData, config).then((res) => {
        if (res.status === 200) this.videoForm.fid = res.data
      })
    },

    onRead2() {
      let formData = new FormData()
      let file = this.posterFile[0].file
      formData.append("file", file, file.name)
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      axios.post(BASE_RUL + "/file/image", formData, config).then((res) => {
        if (res.status === 200) {
          this.articleForm.hasPoster = true
          this.articleForm.poster = res.data
          this.posterShow = false
        }
      })
    },

    submitVideo() {
      if (checkArticleForm(this.articleForm)) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        saveArticle({
              "requestId": this.formData.requestId,
              "title": this.articleForm.title,
              "content": this.articleForm.content,
              "jobType": this.editForm.deadline,
              "jobDay": this.editForm.jobDay,
              "categoryId": this.editForm.categoryId,
              "jobDataType": this.editForm.jobDataType,
              "sex": this.editForm.sex,
              "rewardsCoin": this.editForm.rewardsCoin,
              "attachment": this.videoUrl
            }
        ).then((res) => {
          if (res.code == 0) {
            this.$toast.success("视频稿件发布成功")
            this.videoForm = {}
            this.fileList = []
          }
        })
      }
    },

    async submitArticle() {
      if (checkArticleForm(this.articleForm)) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        saveArticle({
              "requestId": this.formData.requestId,
              "title": this.articleForm.title,
              "content": this.articleForm.content,
              "jobType": this.editForm.deadline,
              "jobDay": this.editForm.jobDay,
              "categoryId": this.editForm.categoryId,
              "jobDataType": this.editForm.jobDataType,
              "sex": this.editForm.sex,
              "rewardsCoin": this.editForm.rewardsCoin,
              "attachment": this.imgUrl
            }
        ).then((res) => {
          if (res.code == 0) {
            this.$toast.success("文章稿件发布成功")
            this.articleForm = {}
            this.posterFile = []
            let id = res.data.id
            setTimeout(() => {
              this.$router.push("/article/details/" + id)
            }, 600)
          }
        })
      }
    },

    selectTag(tag) {
      this.tag = tag
      this.show = false
      if (this.submitType === '1') this.videoForm.type = tag
      if (this.submitType === '2') this.articleForm.type = tag
    },

    onClickLeft() {
      this.$router.back()
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      uploadImg({file: file}).then((res) => {
        if (res.code == 200) {
          this.imgUrl = res.result;
        } else {

        }
      })
    },
    afterRead2(file, path, p) {
      // 此时可以自行将文件上传至服务器
      getUploadUrl({fileName: file.file.name, mode: '1'}).then(async (res) => {
        if (res.code == 0) {
          let data = res.result
          const dataobj = {
            endPoint: data.ossConfig.endpoint,
            port: data.ossConfig.port,
            useSSL: false,
            accessKey: data.ossConfig.accessKey,
            secretKey: data.ossConfig.secretKey,
            secure: false
          }
          const minioClient = new Minio.Client(dataobj)
          var metaData = {
            'Content-Type': file.file.type,
          }
          let fr = new FileReader();//用FileReader 读取文件流
          fr.readAsArrayBuffer(file.file);
          fr.addEventListener("loadend", e => {
            minioClient.putObject('video', data.ossConfig.objectName, new Buffer(e.target.result), file.file.size, function (err, objInfo) {
              if (err) {
                return console.log(err); // err should be null
              }
            })
          })
        }
      })
      getUploadUrl({fileName: file.file.name}).then(async (res) => {
        this.uploadProgress(res.result.uploadUrl, file.file)
      })
    },
    onConfirm(obj) {
      this.editForm.categoryId = obj.value;
      this.editForm.cateText = obj.text;
      this.showPicker = false;
    },
    onConfirm2(obj) {
      this.editForm.jobDataType = obj.value;
      this.editForm.jobDataTypeText = obj.text;
      this.showPicker2 = false;
    },
    uploadProgress(putUrl, file) {
      let http = new XMLHttpRequest();
      http.upload.addEventListener('progress', (e) => {
        const progress = (e.loaded / e.total) * 100;
        console.log(`上传进度: ${progress.toFixed(2)}%`);
        this.progress = progress.toFixed(2)
      }, false);

      http.onload = () => {
        if (http.status === 200 && http.status < 300 || http.status === 304) {

          try {
            //监听http的完成事件，并执行上传完成的监听函数
            const result = http.responseURL
            this.videoUrl = result
            console.log(result)
          } catch (error) {
            //监听错误
            console.error(error)
          }
        }
      }

      http.open("PUT", putUrl, true);
      http.send(file);
    },
    remove() {
      this.progress = 0
    }
  }

}
</script>

<style scoped>

.poster-btn {
  font-size: 13px;
  letter-spacing: 1px;
  display: flex;
  width: 100px;
  height: 50px;
  position: absolute;
  z-index: 99;
  bottom: 15px;
  color: #0066ff;
  right: 3vw;
  line-height: 27px;
}

.poster-btn-img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.in-title {
  color: rgba(0, 0, 0, .7);
  letter-spacing: 1px;
  width: 97%;
  margin: auto;
  height: 50px;
  border: none;
  font-size: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.in-content {
  width: 97%;
  margin-top: 20px;
  font-size: 15px;
  color: rgba(0, 0, 0, .5);
  letter-spacing: 1px;
  border: none;
  line-height: 25px;
}

.footer {
  position: absolute;
  border-bottom: 0;
  font-weight: bold;
  font-size: 14px;
  color: #0066ff;
  padding-top: 4px;
}
</style>
